<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm6 md8 text-left>
        <span class="mainHeader">SUPPLIER</span>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right>
        <v-text-field
          background-color="white"
          v-model="keyword"
          color="#b234a9"
          clearable
          hide-details
          placeholder="Search"
          outlined
          class="rounded-xl"
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12 sm3 md2 class="buttons1" pt-2 pt-sm-0 text-right>
        <v-btn dark color="#3F053C" @click="addSupplier = true">
          <v-icon small>mdi-plus</v-icon> &nbsp; Supplier
        </v-btn>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          class="rounded-lg"
          elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="supplierList">
            <v-flex xs12 v-if="supplierList.length>0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left tablefont"><b>S.No.</b></th>
                      <th class="text-left tablefont"><b>Supplier Name</b></th>
                      <!-- <th class="text-left tablefont"><b>Address</b></th> -->
                      <!-- <th class="text-left tablefont"><b>Supplier Location</b></th> -->
                      <th class="text-left tablefont"><b>Supplier Code</b></th>
                      <th class="text-left tablefont"><b>Mobile Number</b></th>
                      <th class="text-left tablefont"><b>Purchased Gold Net.Wt(gm)</b></th>
                      <th class="text-left tablefont"><b>Balance Thankam (gm)</b></th>
                      <th class="text-left tablefont"><b>Unpaid Gold.Wt(gm)</b></th>
                      <th class="text-left tablefont"><b>Actions</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, i) in supplierList"
                      :key="i"
                      class="table"
                    >
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>
                        <span v-if="value.supplierName">{{
                          value.supplierName
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <!-- <td>
                        <span v-if="value.address">
                          {{ value.address }}</span
                        >
                        <span v-else>-</span>
                      </td> -->
                      <!-- <td>
                        <span v-if="value.supplierLocation">{{
                          value.supplierLocation
                        }}</span>
                        <span v-else>-</span>
                      </td> -->
                      <td>
                        <span v-if="value.supplierCode">{{
                          value.supplierCode
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.contactNumber">{{
                          value.contactNumber
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.totalPurchasedGold">{{
                          value.totalPurchasedGold.toFixed(2)
                        }}</span>
                        <span v-else>0</span>
                      </td>
                      <td>
                        <span v-if="value.balanceThankam">{{
                          value.balanceThankam.toFixed(2)
                        }}</span>
                        <span v-else>0</span>
                      </td>
                      <td>
                        <span v-if="value.totalPendingGold">{{
                          value.totalPendingGold.toFixed(2)
                        }}</span>
                        <span v-else>0</span>
                      </td>
                      
                    
                      <td>
                        <v-layout wrap>
                          <v-flex xs4>
                            <v-icon
                          size="18"
                          color="#3F053C"
                          style="cursor: pointer"
                          @click="
                            $router.push('/SupplierDetail?id=' + value._id)
                          "
                          >mdi-eye</v-icon
                        >
                          </v-flex>
                          <v-flex xs4 px-1>
                            <v-icon
                          size="18"
                          color="#3F053C"
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-pencil</v-icon
                        >
                          </v-flex>
                          <v-flex xs4>
                            <v-icon
                          size="18"
                          color="#3F053C"
                          style="cursor: pointer"
                          @click="(deletedialog = true), (curId = value._id)"
                          >mdi-delete</v-icon
                        >
                          </v-flex>
                        </v-layout>
                        
                       

                       
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1">
              No data found
            </v-flex>
          </v-layout>
        </v-card>
        <v-layout wrap v-if="supplierList">
          <v-flex xs12 pt-4 v-if="supplierList.length>0">
            <v-pagination
              small
              color="#3F053C"
              v-model="page"
              :length="Pagelength"
            >
            </v-pagination>
          </v-flex>
        </v-layout>
        <v-dialog width="900px" v-model="addSupplier">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-8 py-4 rounded-lg">
              <!-- <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center
                ><span style="color: #3f053c; font-size: 25px"
                  >Supplier Info</span
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout> -->
              <!-- <v-layout wrap py-2>
                <v-flex xs11 class="subhead3" text-left></v-flex>
                <v-flex xs1 text-right
                  ><v-icon @click="(addSupplier = false)(resetForm())" color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
              </v-layout> -->

              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Supplier Info</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(addSupplier = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Supplier Name </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        :rules="[rules.required]"
                        type="text"
                        placeholder="Enter Full Name"
                        v-model="supplierName"
                        outlined
                        dense
                        color="#b234a9"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Email Address </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        :rules="[rules.email]"
                        type="email"
                        placeholder="Enter Email"
                        v-model="email"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Mobile Number </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        placeholder="Contact"
                        v-model="contact"
                        outlined
                        color="#b234a9"
                        :rules="phoneRules"
                        dense
                        type="number"
                        hide-details="auto"
                      ></v-text-field>
                      <!-- <v-text-field
                        
                      :rules="rules.phoneRules"
                        type="number"
                        placeholder="Enter Mobile"
                        v-model="contact"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Supplier Address </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Address"
                        v-model="address"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Supplier Location </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Location"
                        v-model="location"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Pan Card No </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Pan Card No"
                        v-model="pan"
                  :rules="panRules"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap py-4>
                <v-flex xs12>
                  <v-divider style="border-color:black; !important"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout wrap py-2>
                <v-flex class="subhead3" text-left>Registration</v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">GSTIN</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter GSTIN"
                        v-model="gstin"
                        outlined
                        dense
                        :rules="[rules.required]"
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">TAN </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter TAN"
                        v-model="tan"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> BIS License </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter BIS License"
                        v-model="bisliscence"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">Insurance Policy No </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Policy No "
                        v-model="policyno"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Company ID </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Company ID"
                        v-model="companyid"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap py-4>
                <v-flex xs12>
                  <v-divider style="border-color:black; !important"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout wrap py-2>
                <v-flex class="subhead3" text-left>Bank Details </v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">Bank Name</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Bank Name"
                        v-model="bankname"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">Account No</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="number"
                        placeholder="Enter Account No"
                        v-model="accno"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">IFSC Code</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter IFSC Code"
                        v-model="ifsc"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">Branch</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Branch"
                        v-model="branch"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Pan Card No </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Pan Card No"
                        v-model="pan"
                  :rules="panRules"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex> -->
                <!-- <v-flex xs4>
                  <v-layout wrap px-4 justify-end fill-height>
                    <v-flex xs12 sm7 text-center align-self-end>
                      <v-btn
                        type="submit"
                        color="#3F053C"
                        class="buttons1"
                        dark
                        block
                        @click="AddSupplier()"
                        ><v-icon>mdi-plus</v-icon>Add</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex> -->
              </v-layout>
              <!-- <v-layout wrap py-4>
                <v-flex xs12>
                  <v-divider style="border-color:black; !important"></v-divider>
                </v-flex>
              </v-layout> -->
            <v-card-actions>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="addSupplier = false"
                >Cancel</v-btn
              > -->
              <v-layout wrap>
              <v-spacer></v-spacer>
                <v-flex xs3>
                  <v-btn
              color="#3F053C"
                        class="buttons1"
                        dark
                        block
                @click="AddSupplier()"
                >OK</v-btn
              >
                </v-flex>
              </v-layout>
            
            </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog width="800px" v-model="editdialogue">
          <v-form v-model="editcat" ref="editcat" @submit.prevent>
          <v-card width="800px" class="pa-4">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit Supplier</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(editdialogue = false)(resetForm2())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-layout wrap>
              <v-flex xs6>
                <v-layout wrap>
                  <v-flex xs12 px-4 text-left class="subhead5">Name</v-flex>
                  <v-flex xs12 px-4>
                    <v-text-field
                      color="#b234a9"
                      placeholder="Name"
                      v-model="curItem.supplierName"
                      outlined
                      :rules="[rules.required]"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4 pt-4 text-left class="subhead5">Phone</v-flex>
                  <v-flex xs12 px-4>
                    <v-text-field
                      color="#b234a9"
                      v-model="curItem.contactNumber"
                      dense
                      :rules="phoneRules"
                      type="number"
                      placeholder="Phone"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4 pt-4 text-left class="subhead5"
                    >Location</v-flex
                  >
                  <v-flex xs12 px-4>
                    <v-text-field
                      v-model="curItem.supplierLocation"
                      dense
                      color="#b234a9"
                      placeholder="Location"
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout wrap>
                  <v-flex xs12 px-4 text-left class="subhead5">Address</v-flex>
                  <v-flex xs12 px-4 pb-4>
                    <v-textarea
                      v-model="curItem.address"
                      color="#b234a9"
                      dense
                      placeholder="Address"
                      outlined
                      hide-details
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 px-4  text-left class="subhead5"
                    >Email</v-flex
                  >
                  <v-flex xs12 px-4>
                    <v-text-field
                      v-model="curItem.email"
                      dense
                      color="#b234a9"
                      :rules="[rules.email]"
                      placeholder="email"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap py-4>
                <v-flex xs12>
                  <v-divider style="border-color:black; !important"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout wrap py-2>
                <v-flex class="subhead3" text-left>Registration</v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">GSTIN</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter GSTIN"
                        v-model="curItem.GSTIN"
                        outlined
                        :rules="[rules.required]"
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">TAN </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter TAN"
                        v-model="curItem.TAN"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> BIS License </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter BIS License"
                        v-model="curItem.BisLicense"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">Insurance Policy No </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Policy No "
                        v-model="curItem.policyNo"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5"> Company ID </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Company ID"
                        v-model="curItem.companyId"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap py-4>
                <v-flex xs12>
                  <v-divider style="border-color:black; !important"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout wrap py-2>
                <v-flex class="subhead3" text-left>Bank Details </v-flex>
              </v-layout>
              <v-layout wrap pb-4>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">Bank Name</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter Bank Name"
                        v-model="curItem.bankName"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">Account No</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="number"
                        placeholder="Enter Account No"
                        v-model="curItem.accountNo"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4>
                  <v-layout wrap px-4>
                    <v-flex xs12 text-left>
                      <span class="subhead5">IFSC Code</span>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        color="#b234a9"
                        type="text"
                        placeholder="Enter IFSC Code"
                        v-model="curItem.IFSCCode"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="(editdialogue = false)(resetForm2())"
                >Cancel</v-btn
              > -->
              <v-btn
                color="#3f053c"
                class="body-2 font-weight-bold"
                dark
                @click="editSupplier()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
          </v-form>
        </v-dialog>
        <v-dialog width="400px" v-model="deletedialog">
          <v-card width="400px" class="pa-2">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="deletedialog = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-card-text class="py-2 px-4 tablefont0"
              >Are you sure you want to delete this supplier?
            </v-card-text>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="deletedialog = false"
                >Cancel</v-btn
              > -->
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="deleteSupplier()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page: 1,
      Pagelength: null,
      addcat: false,
      editcat: false,
      // form:false,
      // valid: false,
      jewelleryId: null,
      rolesList: [],
      supplierName: null,
      email: null,
      contact: null,
      address: null,
      location: null,
      gstin: null,
      tan: null,
      bisliscence: null,
      policyno: null,
      companyid: null,
      bankname: null,
      accno: null,
      ifsc: null,
      branch: null,
      pan: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
      panRules: [
  (value) => !!value || "PAN number is required.",
  (value) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value) || "Invalid PAN number.",
],
aadhaarRules: [
  (value) => !!value || "Aadhaar number is required.",
  (value) => /^\d{12}$/.test(value) || "Invalid Aadhaar number.",
],
      keyword: null,
      addSupplier: false,
      positionList: null,
      supplierList: [],
      curItem: [],
      curId: null,
      editdialogue: false,
      deletedialog: false,
    };
  },
  mounted() {
    this.getSupplier();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  watch: {
    keyword() {
      this.getSupplier();
    },
    page(){
      this.getSupplier();
    },
  },
  methods: {
    resetForm() {
      this.$refs.addcat.reset();
    },
    resetForm2() {
      this.$refs.editcat.reset();
      this.getSupplier();
    },
    getSupplier() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/supplier/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.supplierList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
 
    AddSupplier() {
      if (this.$refs.addcat.validate()) {
        axios({
          url: "/supplier/add",
          method: "POST",
          data: {
            supplierName: this.supplierName,
            email: this.email,
            contactNumber: this.contact,
            address: this.address,
            supplierLocation: this.location,
            GSTIN: this.gstin,
            TAN: this.tan,
            BisLicense: this.bisliscence,
            policyNo: this.policyno,
            companyId: this.companyid,
            bankName: this.bankname,
            accountNo: this.accno,
            IFSCCode: this.ifsc,
            branch: this.branch,
            pancardNo: this.pan,
          },
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.addSupplier = false;
              this.$refs.addcat.resetValidation();
              this.supplierName="",
            this.email=null;
           this.contact=null;
           this.address=null;
            this.location=null;
            this.gstin=null;
           this.tan=null;
            this.bisliscence=null;
           this.policyno=null;
           this.companyid=null;
            this.bankname=null;
           this.accno=null;
           this.ifsc=null;
           this.branch=null;
           this.pan=null;
              this.getSupplier();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    editSupplier() {
      if (this.$refs.editcat.validate()) {
      axios({
        url: "/supplier/edit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          contactNumber: this.curItem.contactNumber,
          supplierName: this.curItem.supplierName,
          supplierLocation: this.curItem.supplierLocation,
          address: this.curItem.address,
          email: this.curItem.email,
          GSTIN: this.curItem.GSTIN,
          TAN: this.curItem.TAN,
          BisLicense: this.curItem.BisLicense,
          policyNo: this.curItem.policyNo,
          companyId: this.curItem.companyId,
          bankName: this.curItem.bankName,
          accountNo: this.curItem.accountNo,
          IFSCCode: this.curItem.IFSCCode,

        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            
            this.$refs.editcat.resetValidation();
            this.getSupplier();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getSupplier();

          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      }
    },
    deleteSupplier() {
      axios({
        url: "/supplier/delete",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.deletedialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getSupplier();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.v-text-field input {
  font-family: kumbhBold;
}
</style>
